/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function ManagementYear() {
    const navigate = useNavigate();
    const [yearsList, setYearsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredYears, setFilteredYears] = useState([]);
    const [open, setOpen] = useState(false);
    const [yearInput, setYearInput] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [yearToDelete, setYearToDelete] = useState(null);

    useEffect(() => {
        fetchYears();
    }, []);

    const fetchYears = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/years`);
            if (Array.isArray(response.data.data.list)) {
                setYearsList(response.data.data.list);
                toast.success('Years fetched from database', {
                    position: 'bottom-left'
                });
            } else {
                console.error('Expected an array but got:', response.data.data.list);
            }
        } catch (error) {
            console.error('Error fetching years:', error);
        }
    };

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredYears(yearsList);
        } else {
            setFilteredYears(
                yearsList.filter((year) =>
                    year.year.toString().includes(searchQuery)
                )
            );
        }
    }, [searchQuery, yearsList]);

    const handleOpen = () => {
        setEditMode(false);
        setYearInput('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setConfirmOpen(false);
    };

    const handleSubmit = async () => {
        try {
            if (editMode && selectedYear) {
                await axios.put(`${BASE_URL}/api/v1/year/${selectedYear._id}`, { year: yearInput });
                toast.success('Year updated successfully', {
                    position: 'bottom-left'
                });
            } else {
                await axios.post(`${BASE_URL}/api/v1/year`, { year: yearInput });
                toast.success('Year added successfully', {
                    position: 'bottom-left'
                });
            }
            fetchYears();
            handleClose();
        } catch (error) {
            console.error('Error saving year:', error);
        }
    };

    const handleEdit = (year) => {
        setSelectedYear(year);
        setYearInput(year.year);
        setEditMode(true);
        setOpen(true);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/v1/year/${yearToDelete._id}`);
            toast.success('Year deleted successfully', {
                position: 'bottom-left'
            });
            fetchYears();
            handleClose();
        } catch (error) {
            console.error('Error deleting year:', error);
        }
    };

    const confirmDelete = (year) => {
        setYearToDelete(year);
        setConfirmOpen(true);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Years
                    </Typography>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                        <TextField
                            size='small'
                            variant='outlined'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ backgroundColor: 'white', width: '300px' }}
                        />
                        <Button variant='contained' color='success' onClick={handleOpen}>
                            Add Year
                        </Button>
                    </Box>
                    <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#262726' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>S.No</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Year</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Edit</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredYears.length > 0 ? (
                                    filteredYears.map((year, index) => (
                                        <TableRow key={year._id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{year.year}</TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='primary' onClick={() => handleEdit(year)}>
                                                    Edit
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='error' onClick={() => confirmDelete(year)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan='4' align='center'>
                                            No years found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editMode ? 'Edit Year' : 'Add Year'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the year number.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Year'
                        type='number'
                        fullWidth
                        value={yearInput}
                        onChange={(e) => setYearInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>{editMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmOpen} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this year?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete} color='error'>Delete</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </>
    );
}

export default ManagementYear;
