import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Paper, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';

function EditQuiz() {
    const location = useLocation();
    const navigate = useNavigate();
    const quiz = location.state?.quiz || {};
    const [name, setName] = useState(quiz.name || '');
    const [description, setDescription] = useState(quiz.description || '');
    const [imageURL, setImageURL] = useState(quiz.imageURL || '');
    const [type, setType] = useState(quiz.type);
    const [subjectId, setSubjectId] = useState(quiz.subject ? quiz.subject._id : '');
    const [yearId, setYearId] = useState(quiz.year ? quiz.year._id : '');
    const [subjectList, setSubjectList] = useState([]);
    const [yearList, setYearList] = useState([]);

    useEffect(() => {
        fetchDropdownData();
    }, []);

    const fetchDropdownData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/common/dropdown-data`);
            if (response.data.status === 200) {
                setSubjectList(response.data.data.subjects);
                setYearList(response.data.data.years);
            } else {
                toast.error('Failed to fetch dropdown data.', {
                    position: 'bottom-left'
                });
            }
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
            toast.error('Error fetching dropdown data.', {
                position: 'bottom-left'
            });
        }
    };

    const handleSave = async () => {
        const updatedQuiz = {
            name,
            description,
            imageURL,
            type,
            subjectId: type === 'subject' ? subjectId : null,
            yearId: type === 'year' ? yearId : null
        };

        try {
            await axios.put(`${BASE_URL}/api/v2/quiz/${quiz._id}`, updatedQuiz);
            toast.success('Quiz updated successfully!', {
                position: 'bottom-left'
            });
            navigate(-1);
        } catch (error) {
            console.error('Error updating quiz:', error);
            toast.error('Failed to update quiz.', {
                position: 'bottom-left'
            });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant="h4" sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Edit Quiz
                    </Typography>
                    <Paper sx={{ p: 3, boxShadow: 3 }}>
                        <TextField
                            fullWidth
                            label="Quiz Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Image URL"
                            value={imageURL}
                            onChange={(e) => setImageURL(e.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <FormControl fullWidth margin="normal" variant="outlined">
                            <InputLabel>Type</InputLabel>
                            <Select value={type} onChange={(e) => setType(e.target.value)} label="Type">
                                <MenuItem value="subject">Subject</MenuItem>
                                <MenuItem value="year">Year</MenuItem>
                            </Select>
                        </FormControl>
                        {type === 'subject' && (
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel>Subject</InputLabel>
                                <Select value={subjectId} onChange={(e) => setSubjectId(e.target.value)} label="Subject">
                                    {subjectList.map((sub) => (
                                        <MenuItem key={sub._id} value={sub._id}>
                                            {sub.subject}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {type === 'year' && (
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel>Year</InputLabel>
                                <Select value={yearId} onChange={(e) => setYearId(e.target.value)} label="Year">
                                    {yearList.map((yr) => (
                                        <MenuItem key={yr._id} value={yr._id}>
                                            {yr.year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Box display="flex" justifyContent="flex" mt={2}>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            <ToastContainer />
        </>
    );
}

export default EditQuiz;
