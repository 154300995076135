/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function ManagementSubjectV2() {
    const navigate = useNavigate();
    const [subjectsList, setSubjectsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubjects, setFilteredSubjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [subjectInput, setSubjectInput] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [subjectToDelete, setSubjectToDelete] = useState(null);

    useEffect(() => {
        fetchSubjects();
    }, []);

    const fetchSubjects = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/subjects`);
            if (Array.isArray(response.data.data.list)) {
                setSubjectsList(response.data.data.list);
                toast.success('Subjects fetched from database', {
                    position: 'bottom-left'
                });
            } else {
                console.error('Expected an array but got:', response.data.data.list);
            }
        } catch (error) {
            console.error('Error fetching subjects:', error);
        }
    };

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredSubjects(subjectsList);
        } else {
            setFilteredSubjects(
                subjectsList.filter((subject) =>
                    subject.subject.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }
    }, [searchQuery, subjectsList]);

    const handleOpen = () => {
        setEditMode(false);
        setSubjectInput('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setConfirmOpen(false);
    };

    const handleSubmit = async () => {
        try {
            if (editMode && selectedSubject) {
                await axios.put(`${BASE_URL}/api/v1/subject/${selectedSubject._id}`, { subject: subjectInput });
                toast.success('Subject updated successfully', {
                    position: 'bottom-left'
                });
            } else {
                await axios.post(`${BASE_URL}/api/v1/subject`, { subject: subjectInput });
                toast.success('Subject added successfully', {
                    position: 'bottom-left'
                });
            }
            fetchSubjects();
            handleClose();
        } catch (error) {
            console.error('Error saving subject:', error);
        }
    };

    const handleEdit = (subject) => {
        setSelectedSubject(subject);
        setSubjectInput(subject.subject);
        setEditMode(true);
        setOpen(true);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/v1/subject/${subjectToDelete._id}`);
            toast.success('Subject deleted successfully', {
                position: 'bottom-left'
            });
            fetchSubjects();
            handleClose();
        } catch (error) {
            console.error('Error deleting subject:', error);
        }
    };

    const confirmDelete = (subject) => {
        setSubjectToDelete(subject);
        setConfirmOpen(true);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Subjects
                    </Typography>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                        <TextField
                            size='small'
                            variant='outlined'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ backgroundColor: 'white', width: '300px' }}
                        />
                        <Button variant='contained' color='success' onClick={handleOpen}>
                            Add Subject
                        </Button>
                    </Box>
                    <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#262726' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>S.No</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Subject</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Edit</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredSubjects.length > 0 ? (
                                    filteredSubjects.map((subject, index) => (
                                        <TableRow key={subject._id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{subject.subject}</TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='primary' onClick={() => handleEdit(subject)}>
                                                    Edit
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='error' onClick={() => confirmDelete(subject)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan='4' align='center'>
                                            No subjects found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editMode ? 'Edit Subject' : 'Add Subject'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the subject name.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Subject'
                        type='text'
                        fullWidth
                        value={subjectInput}
                        onChange={(e) => setSubjectInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>{editMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmOpen} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this subject?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete} color='error'>Delete</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </>
    );
}

export default ManagementSubjectV2;
