// src/navItems.js

import { Home, Search, Logout, Quiz, Settings, Image, CalendarMonth, Note, Subject } from '@mui/icons-material';

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <Home />
    },
    {
        value: 'Add Quiz',
        route: 'add-quiz-v2',
        icon: <Quiz />
    },
    {
        value: 'Quiz Management',
        route: 'exam-management',
        icon: <Settings />
    },
    {
        value: 'Year Management',
        route: 'year-management',
        icon: <CalendarMonth />
    },
    {
        value: 'Subject Management',
        route: 'subject-management',
        icon: <Subject />
    },
    {
        value: 'Image Upload',
        route: 'image-upload',
        icon: <Image />
    },
    {
        value: 'Search Question',
        route: 'search-question',
        icon: <Search />
    }
];

export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <Logout />
};
